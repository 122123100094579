<template v-if="loaded">
  <v-card flat tile class="py-2 px- mb-5 transparent">
    <v-sheet color="charcoal" class="pa-2 paper--text" :style="charcoalTile">
      <v-card-actions class="pl-0 ">
        <span :class="textSize">
          {{ muscle.name }}
        </span>
      </v-card-actions>
    </v-sheet>
    <v-card-text align="center" class="pa-0 ml-n2 caption">
      <BaseTag
        tagsize="small"
        :label="muscle.body_position"
        color="paper darken-1"
      />
      <BaseTag
        tagsize="small"
        :label="muscle.muscle_group"
        color="paper darken-1"
      />
      <BaseTag tagsize="small" :label="muscle.size" color="paper darken-1" />
    </v-card-text>
    <v-row dense class="mt-2" :style="charcoalTileLight">
      <v-col cols="12">
        <FlipCard :dark="true">
          <template v-slot:front>
            <v-img
              :max-height="imageSize"
              :src="muscle.ilustration"
              :alt="muscle.name"
              :title="muscle.name"
              contain
              class="elevation-0"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="progressActive" />
                </v-row>
              </template>
            </v-img>
          </template>
          <template v-slot:back>
            <v-card-text
              :class="textSizeSmall + '  charcoal--text px-2'"
              :style="
                isPhone
                  ? 'overflow-y: auto; height:240px;'
                  : 'overflow-y: auto; height:400px;'
              "
              v-html="muscle.description"
            >
            </v-card-text>
          </template>
        </FlipCard>
      </v-col>
    </v-row>

    <v-sheet class="transparent">
      <v-card-actions class="px-0 pb-1">
        <v-btn color="paper" class="" tonal @click="openContent()">
          VIEW RELATED ITEMS
          <v-icon>
            mdi-open-in-new
          </v-icon>
        </v-btn>
        <v-spacer />
        <template v-if="loggedIn && xvy">
          <BaseActionButton
            color="silver"
            class="mr-n2"
            icon="mdi-pencil"
            plain
            :large="!isPhone"
            v-if="!editMode"
            v-bind="$attrs"
            label="Edit"
            @clickedThis="editMode = true"
          />
          <BaseActionButton
            color="paper"
            plain
            :large="!isPhone"
            v-else
            v-bind="$attrs"
            label="Save"
            @clickedThis="saveMuscle"
          />
        </template>
      </v-card-actions>
    </v-sheet>
    <v-card-text class="px-0">
      <tiptap-vuetify
        v-if="editMode"
        v-model="muscle.description"
        :extensions="extensions"
        placeholder="Write muscle descriptuion here"
      />
      <v-text-field
        class="mt-2"
        dark
        v-if="editMode"
        v-model="muscle.ilustration"
        label="Image/Ilustration URL"
        prepend-icon="mdi-image-edit"
      />
    </v-card-text>
    <template v-if="!editMode">
      <v-sheet rounded class="transparent pa-2" :style="charcoalTileMenu">
        <v-card-title :class="textSizeSmall + ' paper--text pa-0 pb-1'">
          Popular Exercises Using This Muscle
        </v-card-title>
        <v-card-text
          class="pt-0 pl-0 primary--text link"
          v-for="(exercise, index) in exercises"
          :key="index"
        >
          <v-card-actions
            :class="textSizeXSmall + ' pa-0'"
            @click="$router.push('/exercises/' + exercise.exerciseid)"
          >
            <v-icon class="progressActive--text pr-1">
              {{ iconNumber(exercise.rownum) }}
            </v-icon>
            <router-link
              class="paper--text"
              :to="'/exercises/' + exercise.exerciseid"
              :title="exercise.exercise_name"
            >
              {{ exercise.exercise_name }}
            </router-link>
          </v-card-actions>
          <v-card-text class=" pa-0 pl-7 caption silver--text">
            {{ exercise.muscle_type }}
          </v-card-text>
        </v-card-text>
      </v-sheet>
    </template>
    <BaseSnackBar :show="snackbar" :snack="snackText" />
  </v-card>
</template>
<script>
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  HardBreak,
  HorizontalRule
} from 'tiptap-vuetify'
import axios from 'axios'

const FlipCard = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/FlipCard.vue')

export default {
  components: { TiptapVuetify, FlipCard },
  mixins: [util],
  data: () => ({
    muscle: {},
    editMode: false,
    loaded: false,
    snackbar: false,
    snackText: '',
    timeout: 2000,
    extensions: [
      Paragraph,
      Bold,
      Italic,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3, 4, 5, 6]
          }
        }
      ],
      HorizontalRule,
      Link,
      ListItem,
      BulletList,
      OrderedList,
      HardBreak
    ]
  }),
  beforeMount() {
    this.loadMuscle()
  },
  watch: {
    code() {
      this.loadMuscle()
    }
  },
  methods: {
    goTo(path) {
      if (this.$route.path != path) {
        this.$router.push(path)
      } else this.$router.go()
    },
    openContent() {
      this.goTo('/search/bymuscle/' + this.muscle.id)
    },
    loadMuscle() {
      return axios
        .get(this.baseURL + '/util/muscledetail/' + this.code, {})
        .then(response => {
          if (response.status == 200) {
            this.muscle = response.data.data
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    saveMuscle() {
      return axios
        .put(this.baseURL + '/util/muscle', {
          muscle: this.muscle
        })
        .then(response => {
          if (response.status == 200) {
            this.editMode = false
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  },
  computed: {
    imageSize() {
      return this.isPhone ? 240 : 400
    },
    ...appConfig
  },
  props: {
    code: {
      type: String,
      required: true
    },
    exercises: {
      type: Array,
      required: true
    }
  }
}
</script>
